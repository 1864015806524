import { fetchGraphQL } from '@/lib/contentful/api'

const moduleContent2Blocks = {
  name: 'moduleContent2Blocks',

  transform: (data, preview = false) => {
    const { fields } = data

    const getVideoData = (videoFields, fallback) => {
      if (videoFields?.fields?.file?.url) {
          return {
              url: `https:${videoFields.fields.file.url}`,
              type: videoFields.fields.file.contentType || '',
              altTag: videoFields.fields?.description || videoFields.fields?.title || '',
          };
      }
      return fallback || { url: '', type: '' };
    }

    const slides = fields?.slides?.map(slide => {
      const fields = slide.fields || {};


      return {
          eyebrow: fields.eyebrow || '',
          headline: fields.headline || '',
          bodyText: fields.bodyText || '',
          contentIcon: fields.contentIcon?.fields?.file?.url ? `https:${fields.contentIcon.fields.file.url}` : '',
          contentAlt: fields.contentIcon?.fields?.description || fields.contentIcon?.fields?.title || '',
          iconWidth: fields?.iconWidth || 50,
          iconWidthMobile: fields?.iconWidthMobile || 50,
          primaryCta: fields.primaryCta?.fields || {},
          secondaryCta: fields.secondaryCta?.fields || {},
          image: fields.image?.fields?.file?.url ? `https:${fields.image.fields.file.url}` : '',
          imageAlt: fields.image?.fields?.description || fields.image?.fields?.title || '',
          videos: {
            desktop: getVideoData(fields?.video?.fields?.desktop),
            tablet: getVideoData(fields?.video?.fields?.tablet, getVideoData(fields?.video?.fields?.desktop)),
            mobile: getVideoData(fields?.video?.fields?.mobile, getVideoData(fields?.video?.fields?.desktop))
          }
      };
    }) || [];

    return {
      slides: slides || [],
      autoplayTime: fields?.autoplayTime || 0,
      textAndImage: fields?.textAndImage || '',
      entryTitle: fields.entryTitle,
      inset: fields?.inset,
      overlayType: fields?.overlayType,
      eyebrow: fields?.eyebrow,
      imageSize: fields?.imageSize,
      textColor: fields?.textColor?.value,
      backgroundColor: fields?.backgroundColor?.value,
      headline: fields?.headline,
      generalPaddingDisable: fields?.generalPaddingDisable,
      imagePosition: fields?.imagePosition,
      bodyText: fields?.bodyText,
      backgroundImage: fields?.backgroundImage?.fields?.file
        ? 'https:' + fields?.backgroundImage?.fields?.file?.url
        : '',
      altTag: fields?.backgroundImage?.fields?.file
        ? fields?.backgroundImage?.fields?.description
        : '',
      primaryCta: {
        entryTitle: fields?.primaryCta?.fields?.entryTitle,
        label: fields?.primaryCta?.fields?.label,
        url: fields?.primaryCta?.fields?.url,
        openInNewTab: fields?.primaryCta?.fields?.openInNewTab,
        openVideoPlayer: fields?.primaryCta?.fields?.openVideoPlayer,
        id: fields?.primaryCta?.sys?.id,
        backgroundColor: fields?.primaryCta?.fields?.backgroundColor,
      },
      secondaryCta: {
        entryTitle: fields?.secondaryCta?.fields?.entryTitle,
        label: fields?.secondaryCta?.fields?.label,
        url: fields?.secondaryCta?.fields?.url,
        openInNewTab: fields?.secondaryCta?.fields?.openInNewTab,
        openVideoPlayer: fields?.secondaryCta?.fields?.openVideoPlayer,
        id: fields?.secondaryCta?.sys?.id,
        backgroundColor: fields?.secondaryCta?.fields?.backgroundColor,
      },
      // deprecated - replaced by primary and secondary cta
      ctaLeftText: fields?.ctaLeftText,
      ctaRightText: fields?.ctaRightText,
      ctaLeftUrl: fields?.ctaLeftUrl,
      ctaRightUrl: fields?.ctaRightUrl,
      videos:{
        desktop:fields?.video?.fields?.desktop?.fields?.file?.url && 'https:'+fields?.video?.fields?.desktop?.fields?.file?.url,
        mobile:fields?.video?.fields?.mobile?.fields?.file?.url && 'https:'+fields?.video?.fields?.mobile?.fields?.file?.url,
        tablet: fields?.video?.fields?.tablet?.fields?.file?.url && 'https:'+fields?.video?.fields?.tablet?.fields?.file?.url,
      }
    }
  },

  enablePreview: async (module, preview = false) => {
    let id = module.sys.id
    const entry = await fetchGraphQL(
      `query {
        moduleContent2Blocks(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
                backgroundImage{
                  url
                }
                overlayType
                imageSize
                inset
                generalPaddingDisable
                imagePosition
                eyebrow
                headline
                bodyText{
                  json
                }

                primaryCta{
                  label
                  url
                  openInNewTab
                  openVideoPlayer
                  backgroundColor
                }
                secondaryCta{
                  label
                  url
                  openInNewTab
                  openVideoPlayer
                  backgroundColor
                }

                # deprecated - replaced by primary and secondary cta
                ctaLeftText
                ctaRightText
                ctaLeftUrl
                ctaRightUrl
      }
    }`,
      preview
    )
    return entry?.data?.moduleContent2Blocks
  },
}
export default moduleContent2Blocks
