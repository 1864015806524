// 3rd Party
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

// Components
import { useMobileSearchContext } from '@/components/context/MobileSearchContext'
import { getPromobar } from '@/lib/contentful/promobar'
import { NextSeo } from 'next-seo'
import { useToasts } from 'react-toast-notifications'
import Cookies from 'js-cookie'
import { dateFormatMoment } from '@/lib/helpers/momentHelper'
import { useDispatch } from 'react-redux'
import { clearCustomerData } from '@/redux/actions/userAction'
import { logoutRevokeToken } from '@/lib/ping'
import { newsletterSignupEvent } from '@/lib/ga-events'
const jwt = require('jsonwebtoken')
const DynamicHeader = dynamic(() => import('../Header/Header').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})

// footer load when page is loaded
const DynamicFooter = dynamic(() => import('../Footer/Footer').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})

const DynamicMobileSearchView = dynamic(
  () => import('../Header/Menu/MobileMenu/MobileSearchView').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: false,
  }
)

const Layout = ({
  children,
  isFooter,
  hideSearch = false,
  hideSubMenu = false,
  layoutData,
  loadingUrl,
  navigateSystemBuilder,
  preview,
  setModal,
  setNavigateSystemBuilder,
  setPayment,
  seo,
  className,
}) => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const promodata = getPromobar()
  const router = useRouter()
  const { mobileSearch, setMobileSearch } = useMobileSearchContext()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const isFullHeight = () => {
    const isFirstModuleHeroSection =
      children?.props?.modules &&
      children?.props?.modules.length >= 0 &&
      (children.props.modules[0].sys?.contentType?.sys?.id == 'moduleHeroSection' ||
        children.props.modules[0].sys?.contentType?.sys?.id == 'moduleHero')
        ? true
        : false

    if (isFirstModuleHeroSection) {
      let heroType =
        children.props.modules[0].sys?.contentType?.sys?.id == 'moduleHeroSection'
          ? 'moduleHeroSection'
          : 'moduleHero'
      const ifFullHeight =
        heroType == 'moduleHeroSection'
          ? children.props.modules[0].fields?.fullHeight
          : children.props.modules[0].fields?.type == 'Full'

      if (ifFullHeight) {
        return true
      }
    }
    return false
  }
  const setMargintop = isFullHeight()
  const messageText = (promodata && promodata.fields?.message?.content[0]?.content[0]?.value) || ''

  useEffect(() => {
    //logic for token expire

    const token = Cookies.get('customer_token')
    if (token) {
      const tokenExpiry = jwt.decode(token)
      if (tokenExpiry?.exp < dateFormatMoment(new Date(), 'X')) {
        // millisecond format
        logoutRevokeToken()
        Cookies.remove('customer_token')
        Cookies.remove('sitka_customer_token', { domain: process.env.NEXT_PUBLIC_SUB_DOMAIN })
        dispatch(clearCustomerData())
        addToast('Token is Expired.', { appearance: 'error' })
      }
    }
    //
    if (typeof window != 'undefined') {
      let formId = document.querySelector('.klaviyo_form_trigger')
      if (formId) {
        formId.addEventListener('click', function () {
          window._klOnsite = window._klOnsite || []
          window._klOnsite.push(['openForm', formId.dataset.form])
        })
      }
    }
  }, [])

  useEffect(() => {
    const handleMutation = (mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        // Check if the mutation is related to childList changes
        if (mutation.type === 'childList') {
          // Iterate over added nodes and check for a specific class
          mutation.addedNodes.forEach((addedNode) => {
            if (addedNode.nodeType === 1 && addedNode.classList.contains('needsclick')) {
              let formId = document.querySelector('.klaviyo-form')
              if (formId && formId.dataset && formId.dataset.testid) {
                let formIdVal = formId.dataset.testid.split('-')
                formIdVal = formIdVal[formIdVal.length - 1]
                let datalayerLastEvent = dataLayer.filter(
                  (data) => data.event == 'newsletter_signup'
                )
                datalayerLastEvent = datalayerLastEvent[datalayerLastEvent.length - 1]
                // console.log(datalayerLastEvent, window.location.href)
                if (
                  datalayerLastEvent &&
                  datalayerLastEvent.event == 'newsletter_signup' &&
                  datalayerLastEvent.newsletter_location == formIdVal &&
                  datalayerLastEvent.location_id == window.location.href
                ) {
                  console.log('duplicate')
                  // SKIP DUPLICATE EVENTS
                } else {
                  console.log('new event added')
                  newsletterSignupEvent(formIdVal)
                }
              }
            }
          })
        }
      })
    }

    const observer = new MutationObserver(handleMutation)
    const observerConfig = {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true,
    }

    observer.observe(document, observerConfig)

    return () => {
      observer.disconnect()
    }
  }, [])

  //GOREMS-1134 - Add current URL as fallback to the canonicalUrl
  let processSeo = { ...seo }
  if (
    !processSeo?.fields?.canonicalUrl ||
    processSeo?.fields?.canonicalUrl == null ||
    processSeo?.fields?.canonicalUrl == ''
  ) {
    let pagePath
    // For the PLP page
    if (router.query && router.query.page && router.query.page == 1) {
      pagePath = router?.asPath?.includes('?page=')
        ? router.asPath?.split('?')[0]
        : router.asPath.split('&page=')[0]
    } else {
      pagePath = router?.asPath
    }
    let basePath = process.env.NEXT_PUBLIC_BASE_URL?.replace(/^\/|\/$/g, '')

    pagePath = pagePath?.replace(/^\/|\/$/g, '')
    processSeo.fields = {
      ...processSeo.fields,
      canonicalUrl: processSeo?.canonicalUrl || `${basePath}/${pagePath}`,
    }
  }

  const layoutInlineStyles = {
    color: layoutData?.textColor || null,
    backgroundColor: layoutData?.backgroundColor || null,
  };
  const globalTextColorClass = layoutData?.textColor ? 'has-global-text-color' : '';
  const globalBackgroundColorClass = layoutData?.backgroundColor ? 'has-global-background-color' : '';

  return (
    <div className="relative" id="main">
      {/* GOREMS-1134 */}
      {processSeo && (
        <NextSeo
          title={processSeo?.fields?.title || processSeo?.title || ''}
          description={processSeo?.fields?.description || processSeo?.description || ''}
          canonical={
            processSeo?.fields?.canonicalUrl?.endsWith('/')
              ? processSeo?.fields?.canonicalUrl.slice(0, -1)
              : processSeo?.fields?.canonicalUrl || ''
          }
          additionalMetaTags={
            processSeo?.additionalMetaTags || [
              {
                name: 'keywords',
                content: processSeo?.fields?.keywords || '',
              },
            ]
          }
        />
      )}
      <DynamicHeader
        hideSearch={hideSearch}
        hideSubMenu={hideSubMenu}
        isOpenModal={isMobileMenuOpen}
        loadingUrl={loadingUrl}
        menu={layoutData?.menu}
        navigateSystemBuilder={navigateSystemBuilder}
        preview={preview}
        setIsOpenModal={setIsMobileMenuOpen}
        setModal={setModal}
        setNavigateSystemBuilder={setNavigateSystemBuilder}
        setPayment={setPayment}
      />
      <div
        className={`absolute w-screen ${
          setMargintop
            ? 'top-0'
            : hideSearch
            ? 'top-0 lg:top-[82px]'
            : promodata && messageText && promodata.fields.show
            ? 'top-[160px] lg:top-[200px]'
            : 'top-[110px] lg:top-[150px]'
        }`}
      >
        <div
          className={`layout-body ${globalTextColorClass} ${globalBackgroundColorClass} ${className ?? ' '}`}
          style={layoutInlineStyles}
        >
          {children}
        </div>
        {isFooter ? null : (
          <DynamicFooter
            {...(layoutData?.footer || layoutData?.layoutData?.footer)}
            preview={preview}
          />
        )}
      </div>
    </div>
  )
}

export default Layout
