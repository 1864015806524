// 3rd party
import { useState, useEffect } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useRouter } from 'next/router'
import { INLINES } from '@contentful/rich-text-types'

// Components
import CTA from '@/components/CTA'

// Contentful
import { enable3UpText } from '@/lib/contentful/module'

const Module3UpText = (props) => {
  const router = useRouter()
  const { preview } = props
  const [item, setItem] = useState(props)

  useEffect(async () => {
    if (preview) {
      const res = await enable3UpText(props, preview)
      setItem(res)
    }
  }, [])

  const onButtonClick = () => {
    if (item.buttonTitle && item.buttonTitle.toLowerCase() == 'sign up today') {
      router.push('?create-an-account=true', undefined, { scroll: false })
    }
  }

  const cta1 = {
    id: item?.cta1?.id || null,
    label: item?.cta1?.label || item?.ctaRightText,
    openInNewTab: item?.cta1?.openInNewTab || false,
    openVideoPlayer: item?.cta1?.openVideoPlayer || false,
    url: item?.cta1?.url || item?.ctaRightUrl,
    bgColor: item?.cta1?.backgroundColor,
  }

  const cta2 = {
    id: item?.cta2?.id || null,
    label: item?.cta2?.label || item?.ctaRightText2,
    openInNewTab: item?.cta2?.openInNewTab || false,
    openVideoPlayer: item?.cta2?.openVideoPlayer || false,
    url: item?.cta2?.url || item?.ctaRightUrl2,
    bgColor: item?.cta2?.backgroundColor,
  }

  const cta3 = {
    id: item?.cta3?.id || null,
    label: item?.cta3?.label || item?.ctaRightText3,
    openInNewTab: item?.cta3?.openInNewTab || false,
    openVideoPlayer: item?.cta3?.openVideoPlayer || false,
    url: item?.cta3?.url || item?.ctaRightUrl3,
    bgColor: item?.cta3?.backgroundColor,
  }

  const ctaClassName =
    'flex uppercase font-bold tracking-widest text-xs leading-[15px] border-b-2 border-primary w-fit cursor-pointer'

    const renderOptions = {
      renderNode: {  [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node?.data?.uri} className="inline-block text-[#DD5F13]" target="_blank">
            {children}
          </a>
        )
      },}
    }

  return (
    <>
      {
        <div
          className={`
          container w-full
          ${props?.sys?.id == '5AGQT16B4UCy7gdR6bUCSB' ? 'affiliate-promise' : ''}
        `}
        >
          {item?.generalTitle ? (
            <div className="text-lg md:text-xl font-extrabold uppercase leading-loose mb-5 header">
              {item.generalTitle}
            </div>
          ) : null}
          {item?.generalSubtitle ? (
            <div className="text-sm mb-5 subheader">{item.generalSubtitle}</div>
          ) : null}
        </div>
      }

      {item?.inset ? (
        <div
          className={`
          grid grid-cols-3 md:gap-[60px] gap-y-10 md:gap-5 container w-full
          ${
            item?.sectionName.includes('Brands')
              ? 'mb-[50px] mt-[50px] md:mt-[100px] md:mb-[80px]'
              : 'mt-[30px]'
          }
          ${props.sys.id == '6qr3VK9GejnrYRRSvJ0GtA' && 'mb-[50px] mt-0 md:mt-[100px] md:mb-[80px]'}
          ${
            props.sys.id == '5AGQT16B4UCy7gdR6bUCSB'
              ? 'mb-[30px] md:mb-[20px] affiliate-promise-steps gap-y-5'
              : 'mb-[100px] md:mb-[80px]'
          }
        `}
        >
          <div className="column col-span-3 md:col-span-1 pr-0 md:pr-[60px] md:border-r-2 border-r-0 border-b-2 md:border-b-0 md:py-0 border-gray-200 pb-10 md:pb-0">
            <div className="text-md text-black uppercase font-bold md:tracking-widest mb-2 md:mb-4 tracking-[0.08em] leading-7">
              {item?.headline}
            </div>
            <div className="text hidden md:block text-sm mb-5 d-block text-[#4C4442]">
              {item?.bodyText?.data ? documentToReactComponents(item.bodyText,renderOptions) : item.bodyText}
            </div>
            {cta1.url && cta1.label && (
              <CTA
                className={ctaClassName}
                label={cta1.label}
                openInNewTab={cta1.openInNewTab}
                openVideoPlayer={cta1.openVideoPlayer}
                url={cta1.url}
                background={cta1.bgColor}
                moduleProps={props}
              />
            )}
          </div>
          <div className="column col-span-3 md:col-span-1 pr-0 md:pr-[60px] md:border-r-2 border-r-0 border-b-2 md:border-b-0 md:py-0 border-gray-200 pb-10 md:pb-0">
            <div className="text-md text-black uppercase font-bold md:tracking-widest mb-2 md:mb-4 tracking-[0.08em] leading-7">
              {item?.headline2}
            </div>
            <div className="text hidden md:block text-sm mb-5 d-block text-[#4C4442]">
              {item?.bodyText2?.data ? documentToReactComponents(item.bodyText2,renderOptions) : item.bodyText2}
            </div>
            {cta2.url && cta2.label && (
              <CTA
                className={ctaClassName}
                label={cta2.label}
                openInNewTab={cta2.openInNewTab}
                openVideoPlayer={cta2.openVideoPlayer}
                url={cta2.url}
                background={cta2.bgColor}
                moduleProps={props}
              />
            )}
          </div>
          <div className="column col-span-3 md:col-span-1 pr-0 md:pr-[30px] md:py-0 ">
            <div className="text-md text-black uppercase font-bold md:tracking-widest mb-2 md:mb-4 tracking-[0.08em] leading-7">
              {item?.headline3}
            </div>
            <div className="text hidden md:block text-sm md:mb-5 mb-[10px] d-block text-[#4C4442]">
              {item?.bodyText3?.data ? documentToReactComponents(item.bodyText3,renderOptions) : item.bodyText3}
            </div>
            {cta3.url && cta3.label && (
              <CTA
                className={ctaClassName}
                label={cta3.label}
                openInNewTab={cta3.openInNewTab}
                openVideoPlayer={cta3.openVideoPlayer}
                url={cta3.url}
                background={cta3.bgColor}
                moduleProps={props}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className={`relative md:flex grid grid-cols-1 gap-10 container w-full md:mb-20 ${
            item?.sectionName?.includes('Brands') ? 'md:mt-[100px]' : 'my-[50px]'
          } mb-[60px]`}
        >
          <div className={`image-gallery-block grid lg:grid-cols-3 text-white`}>
            <div className={`max-w-[100%] lg:order-1 mx-8`}>
              <h1 className="text-md font-bold leading-1 tracking-widest uppercase">
                {item?.headline}
              </h1>
              <div className="image-gallery-block-body-text my-4 font-light leading-[22px] tracking-[0.02em] text-[#4C4442] hidden md:block">
                {item?.bodyText?.data ? documentToReactComponents(item.bodyText,renderOptions) : item.bodyText}
              </div>
              {cta1.url && cta1.label && (
                <CTA
                  className={ctaClassName}
                  label={cta1.label}
                  openInNewTab={cta1.openInNewTab}
                  openVideoPlayer={cta1.openVideoPlayer}
                  url={cta1.url}
                  background={cta1.bgColor}
                  moduleProps={props}
                />
              )}
            </div>
            <div
              className={`max-w-[100%] lg:order-2 text-black px-8 lg:py-0 py-5 lg:my-0 my-5 lg:border-y-0 border-y-2 lg:border-x-2 border-solid border-gray-300`}
            >
              <h1 className="text-md font-bold leading-1 tracking-widest uppercase">
                {item?.headline2}
              </h1>
              <div className="image-gallery-block-body-text text-[14px] my-4 font-light leading-[22px] tracking-[0.02em] text-[#4C4442] hidden md:block">
                {item?.bodyText2?.data ? documentToReactComponents(item.bodyText2,renderOptions) : item.bodyText2}
              </div>
              {cta2.url && cta2.label && (
                <CTA
                  className={ctaClassName}
                  label={cta2.label}
                  openInNewTab={cta2.openInNewTab}
                  openVideoPlayer={cta2.openVideoPlayer}
                  url={cta2.url}
                  background={cta2.bgColor}
                  moduleProps={props}
                />
              )}
            </div>
            <div className={`max-w-[100%] lg:order-3 text-black mx-8`}>
              <h1 className="text-md font-bold leading-1 tracking-widest uppercase">
                {item?.headline3}
              </h1>
              <div className="image-gallery-block-body-text text-[14px] my-4 font-light leading-[22px] tracking-[0.02em] text-[#4C4442] hidden md:block">
                {item?.bodyText3?.data ? documentToReactComponents(item.bodyText3,renderOptions) : item.bodyText3}
              </div>
              {cta3.url && cta3.label && (
                <CTA
                  className={ctaClassName}
                  label={cta3.label}
                  openInNewTab={cta3.openInNewTab}
                  openVideoPlayer={cta3.openVideoPlayer}
                  url={cta3.url}
                  background={cta3.bgColor}
                  moduleProps={props}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {item?.buttonTitle ? (
        <div
          className={`
          w-full flex md:justify-end justify-center container mb-[30px] md:mb-[50px]
          ${props?.sys?.id == '5AGQT16B4UCy7gdR6bUCSB' ? 'affiliate-promise-button' : ''}
        `}
        >
          <button
            onClick={() => onButtonClick()}
            className="btn btn-primary px-10 py-5 w-full md:w-auto"
          >
            {item.buttonTitle}
          </button>
        </div>
      ) : null}

      <style jsx>{`
        .dec-primary {
          border-bottom: 2px solid #dd5f13;
          padding-bottom: 2px;
        }
        .affiliate-promise .header {
          color: #0c1319;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.08em;
          margin-bottom: 22px;
        }
        .affiliate-promise .subheader {
          line-height: 18px;
          letter-spacing: 0.02em;
          color: #0c1319;
          margin-bottom: 0;
        }
        .affiliate-promise-steps .column {
          padding-bottom: 0;
        }
        .affiliate-promise-steps .column:last-child {
          padding-right: 0;
        }
        .affiliate-promise-steps .text {
          display: block;
          color: #4c4442;
        }
        @media (min-width: 862px) {
          .affiliate-promise {
            margin-top: 80px;
            max-width: 845px;
          }
          .affiliate-promise-steps {
            margin-top: 50px;
            max-width: 845px;
          }
          .affiliate-promise-button {
            max-width: 845px;
          }
        }
        @media (max-width: 768px) {
          .affiliate-promise {
            margin-top: 30px;
          }
        }
      `}</style>
    </>
  )
}

export default Module3UpText
